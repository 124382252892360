import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { map, mergeMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RecordStatusTypes } from '../typings';
import { HubsService } from './hubs.service';

@Injectable({
  providedIn: 'root'
})
export class SpacesService {

  private _collectionName: string = 'spaces';

  constructor(
    private ngFirestore: AngularFirestore,
    private hubService: HubsService,
  ) { }

  public fetchSpacesList() {
    return this.ngFirestore.collection(this._collectionName).valueChanges({idField: 'id'}).pipe(
      map(dbRecords => {
        return dbRecords.map((dataItem: any) => {
          const hubObservable = this.hubService.fetchHubById(dataItem.hubId);

        const combinedData = combineLatest(
          hubObservable,
          (hubRecord) => {
          return  { hubRecord };
        });

        return combinedData.pipe(
          map((nestedData: any) => {
            return {
              ...dataItem,
              createdAt: (dataItem.createdDate && dataItem.createdDate.toDate()) || dataItem.createdDate,
              hub: nestedData.hubRecord,
            }
          })
        )
      });
    }),
    mergeMap(observables => combineLatest(observables))
    )
  }

  public fetchRecordById(recordId: string) {
    return this.ngFirestore.collection(this._collectionName).doc(recordId).get().pipe(
      map((docResult: DocumentSnapshot<any>) => {
        return docResult.data();
      })
    );
  }

  public countActiveRecords() {
    return this.ngFirestore.collection(this._collectionName, ref => ref.where('status', '==', 'active')).get().pipe(
      map((docsResult: QuerySnapshot<any>) => {
        return docsResult.docs.length;
      })
    );
  }

  public updateRecordStatus(recordId: string, newStatus: RecordStatusTypes, oldStatus: RecordStatusTypes) {
    return this.ngFirestore.collection(this._collectionName).doc(recordId).update({
      status: newStatus,
      prevStatus: (newStatus === RecordStatusTypes.DELETED) ? oldStatus: null
    });
  }

  public removeRecordPermanently(recordId: string) {
    return this.ngFirestore.collection(this._collectionName).doc(recordId).delete();
  }

  public handleBulkUpdate(selectedRecord: string[], newStatus: string) {
    const batch = this.ngFirestore.firestore.batch();
    const collectionRef = this.ngFirestore.collection(this._collectionName).ref;

    for (const id of selectedRecord) {
      batch.update(collectionRef.doc(id), {
        status: newStatus,
        prevStatus: 'pending'
      });
    }

    return batch.commit();
  }

  // public changeFields() {
  //   this.ngFirestore.collection(this._collectionName).ref.get().then(result => {
  //     let batch = this.ngFirestore.firestore.batch();
  //     result.docs.forEach(docRef => {
  //       const data = docRef.data();
  //       batch.update(docRef.ref, {
  //         isDeleted: firebase.default.firestore.FieldValue.delete(),
  //         isRejected: firebase.default.firestore.FieldValue.delete(),
  //         isApproved: firebase.default.firestore.FieldValue.delete(),
  //         isActive: firebase.default.firestore.FieldValue.delete(),
  //       });
  //     })
  //     batch.commit().catch(err => console.error(err));
  //   })
  // }

  public updateCountCategories(collectionName, catName) {
    console.log('--->', collectionName, catName);
    return this.ngFirestore.collection(collectionName, ref => ref.where('name', '==', catName)).get().pipe(
      map(data => {
        return data.docs.map(docItem => {
          return {
            id: docItem.id,
            ...(docItem.data() as object)
          };
        });
      })
    );
  }
  public updateCount(collectionName: string, docId: string, count: any) {
    const collectionRef = this.ngFirestore.collection(collectionName).ref;
    return collectionRef.doc(docId).update({
      count: count
    });
  }
}
