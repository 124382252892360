<ng-container *ngIf="name">
  <span 
    nbTooltip="Open Page"
    nbTooltipStatus="primary"
    class="title" 
    (click)="navigateToDetailPage()"
  >
    <ng-container *ngIf="onlyName; else avatar">{{name}}</ng-container>
    <ng-template #avatar>
      <nb-user 
        size="medium"
        [name]="name"
        [picture]="picture"
      ></nb-user>
    </ng-template>
    <i class="fa fa-external-link-alt icon"></i>
  </span>
</ng-container>