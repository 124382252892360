import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ngx-ag-image-component",
  templateUrl: "./ag-image-component.component.html",
  styleUrls: ["./ag-image-component.component.scss"],
})
export class AgImageComponentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  params: any;
  agInit(params: any) {
    this.params = params;
  }
}
