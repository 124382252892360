import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  NbButtonGroupModule,
  NbButtonModule,
  NbIconModule,
  NbCheckboxModule,
  NbUserModule,
  NbTooltipModule,
  NbDialogModule,
  NbListModule,
} from "@nebular/theme";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

import { SubTabsComponent } from "./components/sub-tabs/sub-tabs.component";
import { ListingsFilterActionsComponent } from "./components/listings-filter-actions/listings-filter-actions.component";
import { AdvancedListingFilterComponent } from "./components/advanced-listing-filter/advanced-listing-filter.component";
import { ListingRowsCountComponent } from "./components/listing-rows-count/listing-rows-count.component";
import { ClickableTitleComponent } from "./components/clickable-title/clickable-title.component";
import { ListitemStatusComponent } from "./components/listitem-status/listitem-status.component";
import { SublistListingCellComponent } from "./components/sublist-listing-cell/sublist-listing-cell.component";
import { SublistModalComponent } from "./components/sublist-modal/sublist-modal.component";
import { ListingComponent } from "./components/listing/listing.component";
import { AgGridModule } from "ag-grid-angular";
import { AgImageComponentComponent } from "./components/ag-grid/ag-image-component/ag-image-component.component";

@NgModule({
  declarations: [
    SubTabsComponent,
    ListingsFilterActionsComponent,
    AdvancedListingFilterComponent,
    ListingRowsCountComponent,
    ClickableTitleComponent,
    ListitemStatusComponent,
    SublistListingCellComponent,
    SublistModalComponent,
    ListingComponent,
    AgImageComponentComponent,
  ],
  imports: [
    CommonModule,
    NbButtonGroupModule,
    NbButtonModule,
    NbIconModule,
    NbCheckboxModule,
    NbUserModule,
    NbTooltipModule,
    NbDialogModule,
    NbListModule,
    BsDropdownModule,
    AgGridModule.withComponents([AgImageComponentComponent]),
  ],
  providers: [],
  exports: [
    SubTabsComponent,
    ListingsFilterActionsComponent,
    AdvancedListingFilterComponent,
    ListingRowsCountComponent,
    ClickableTitleComponent,
    ListitemStatusComponent,
    SublistListingCellComponent,
    SublistModalComponent,
    ListingComponent,
    AgGridModule,
  ],
})
export class SharedModule {}
