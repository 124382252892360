import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UtilRecord } from '../typings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  private collections = {
    main: 'utils',
    institutions: 'institutions',
    languages: 'availableLanguages',
    expertise: 'expertiseLevels',
    target_audience: 'audienceTypes',
    amenities: 'amenities',
    facilities: 'facilities',
  }

  constructor(
    private ngFireStore: AngularFirestore,
  ) { }

  public fetchDataList(tabName: string): Observable<UtilRecord[]> {
    return this.ngFireStore.collection(this.collections.main).doc(this.collections[tabName]).valueChanges().pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  public updateAppDataList(updatedCats: UtilRecord[], tabName: string) {
    return this.ngFireStore.collection(this.collections.main).doc(this.collections[tabName]).set({
      data: updatedCats
    });
  }

}
