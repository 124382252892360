import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'admin-listing-rows-count',
  templateUrl: './listing-rows-count.component.html',
  styleUrls: ['./listing-rows-count.component.scss']
})
export class ListingRowsCountComponent implements OnInit {

  @Input() totalRows: number = 0;
  @Input() selectedRows: number = 0;
  @Input() start: number = 0;
  @Input() end: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
