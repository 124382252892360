import { first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { UsersTypes } from '../typings/users-roles.type';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private _collectionName: string = 'users';
  userlist: any = [];

  constructor(
    private ngRTdb: AngularFireDatabase,
    private fireDb: AngularFirestore,
  ) { }

  /**
   * fetchUserById
   * @description Fetch single user with ID
   */
  public fetchUserById(userId: string) {
    if (!userId) {
      return from(Promise.resolve(null));
    }
    return from(this.ngRTdb.database.ref().child(this._collectionName).child(userId).get().then(snapshot => {
      return snapshot.val();
    }));
  }

  /**
   * @description Handles Users special profile approval/rejection e.g learner, expert or hub ownership
   */
  public handleProfileApproval(userType: string, userId: string, isApproved: boolean): Promise<any> {

    if (userType === UsersTypes.EXPERT) {
      return this._handleApproval('expertProfile', userId, isApproved);
    }

    if (userType === UsersTypes.HUBOWNER) {
      return this._handleApproval('hubOwnership', userId, isApproved);
    }
    throw new Error('This action is not integrated yet.');
  }

  /**
 * @description Handles Users special profile active/archive e.g learner, expert or hub ownership
 */
  public handleProfileActivation(userType: string, userId: string, isActive: boolean): Promise<any> {
    if (userType === UsersTypes.LEARNER) {
      return this._handleSpecialProfileActive('learnerProfile', userId, isActive);
    }

    if (userType === UsersTypes.EXPERT) {
      return this._handleSpecialProfileActive('expertProfile', userId, isActive);
    }

    if (userType === UsersTypes.HUBOWNER) {
      return this._handleSpecialProfileActive('hubOwnership', userId, isActive);
    }
    throw new Error('This action is not integrated yet.');
  }

  /**
   * @description handles user profile deletion
   */
  public handleProfileDeletion(userType: string, userId: string, isDeleted: boolean): Promise<any> {
    if (userType === UsersTypes.LEARNER) {
      return this._handleProfileTrash('learnerProfile', userId, isDeleted);
    }

    if (userType === UsersTypes.EXPERT) {
      return this._handleProfileTrash('expertProfile', userId, isDeleted);
    }

    if (userType === UsersTypes.HUBOWNER) {
      return this._handleProfileTrash('hubOwnership', userId, isDeleted);
    }
    throw new Error('This action is not integrated yet.');
  }

  /**
 * @description handles user profile deletion
 */
  public handleProfileRemoval(userType: string, userId: string): Promise<any> {
    if (userType === UsersTypes.LEARNER) {
      return this._handleLearnerDeletion(userId);
    }

    if (userType === UsersTypes.EXPERT) {
      return this._handleExpertDeletion(userId);
    }

    if (userType === UsersTypes.HUBOWNER) {
      return this._handleHubOwnerDeletion(userId);
    }
    throw new Error('This action is not integrated yet.');
  }

  private _handleApproval(profileField: string, userId: string, isApproved: boolean) {
    return this.ngRTdb.database.ref(this._collectionName).child(userId).child(profileField).update({
      isApproved: isApproved,
      isRejected: !isApproved,
    });
  }

  private _handleSpecialProfileActive(profileField: string, userId: string, isActive: boolean) {
    return this.ngRTdb.database.ref(this._collectionName).child(userId).child(profileField).update({
      isActive: isActive,
    });
  }

  private _handleProfileTrash(profileField: string, userId: string, isDeleted: boolean) {
    return this.ngRTdb.database.ref(this._collectionName).child(userId).child(profileField).update({
      isDeleted: isDeleted,
    });
  }

  private _handleExpertDeletion(userId: string) {
    return this.ngRTdb.database.ref(this._collectionName).child(userId).update({
      isExpert: false,
      expertProfile: null
    });
  }

  private _handleHubOwnerDeletion(userId: string) {
    return this.ngRTdb.database.ref(this._collectionName).child(userId).update({
      myAgency: null,
      hubOwnership: null
    });
  }

  private _handleLearnerDeletion(userId: string) {
    return this.ngRTdb.database.ref(this._collectionName).child(userId).child('learnerProfile').remove();
  }

  /**
  * @param no param  for it return all active scholar
  * @returns
  */
  /* //for testing
   getUserAlldata() {
   return this.fireDb
     .collection(`users`)
     .snapshotChanges()
     .pipe(
       map((_elel) => {
         return _elel
           .map((_last) => {
             return {
               ...(_last.payload.doc.data() as any),
               $key: _last.payload.doc.id,
             }
           });
       })
    );
  }

  try2() { //it works!
   return this.fireDb
     .collection('users')
     .snapshotChanges()
     .pipe(
       map((snaps) =>
         snaps.map((snap) => {
           return ({
             id: snap.payload.doc.id,
             ...(snap.payload.doc.data() as []),
           });
         }),
       ),
     );
  }
  */

  async getALL() {
    const res = await this.fireDb
      .collection('users')
      .get().pipe(first()).toPromise();
    return res.docs.map(e => {
      return {
        id: e.id,
        ...(e.data() as any)
      };
    });
  }

  getExpertUserG = (uid) => {
    //console.log(uid);
    return this.fireDb
      .collection(`users`)
      .doc(uid)
      .collection('profiles')
      .doc('expertProfile')
      .get()
      .pipe(
        map((_data) => {
          if (_data.exists) {
            return {
              ...(_data.data() as any),
              $key: uid,
            };
          } else {
            return null;
          }
        })
      );
  };
  getLearnerUserG = (uid) => {
    return this.fireDb
      .collection(`users`)
      .doc(uid)
      .collection('profiles')
      .doc('learnerProfile')
      .get()
      .pipe(
        map((_data) => {
          if (_data.exists) {
            return {
              ...(_data.data() as any),
              $key: uid,
            };
          } else {
            return null;
          }
        })
      );
  };

  getleanerUser = (uid) => {
    return this.fireDb
      .collection(`users`)
      .doc(uid)
      .collection('profiles')
      .doc('learnerProfile')
      .get()
      .pipe(
        map((_data) => {
          if (_data.exists) {
            return {
              ...(_data.data() as any),
              $key: uid,
            };
          } else {
            return null;
          }
        })
      );
  };

  getExpertUser(uid) {
    return this.fireDb
      .collection(`users`)
      .doc(uid)
      .collection('profiles')
      .doc('expertProfile')
      .get()
      .pipe(
        map((_data) => {
          if (_data.exists) {
            return {
              ...(_data.data() as any),
              $key: uid,
            };
          } else {
            return null;
          }
        })
      );
  }
}