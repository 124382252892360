import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-email-action',
  templateUrl: './email-action.component.html',
  styleUrls: ['./email-action.component.scss']
})
export class EmailActionComponent implements OnInit {

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.currentRoute.queryParams.subscribe(params => {
      // If code isn't there
      if (!params.oobCode || !params.mode) {
        this.router.navigateByUrl('/auth/login');
        return;
      }


      if (params.mode === 'resetPassword') {
        this.goToPasswordResetPage();
      }

    })
  }

  private goToPasswordResetPage() {
    this.router.navigate(['/auth/reset-password'], { queryParamsHandling: 'preserve'});
  }

}
