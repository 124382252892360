import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-advanced-listing-filter',
  templateUrl: './advanced-listing-filter.component.html',
  styleUrls: ['./advanced-listing-filter.component.scss']
})
export class AdvancedListingFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
