<span *ngIf="deleted; else pendingIcon" class="text-danger resolve-status trashed">
  <nb-icon icon="trash" pack="fa" size="small"></nb-icon>Trashed
</span>

<ng-template #pendingIcon>
  <span 
    *ngIf="pending; else approvedIcon"
    [nbTooltip]="drafted ? 'Drafted': 'Pending'"
    nbTooltipStatus="primary" 
    [ngClass]="{'resolve-status': true, 'pending': true, 'drafted': drafted}">
    <nb-icon icon="ellipsis-h" pack="fa"></nb-icon>
  </span>
</ng-template>

<ng-template #approvedIcon>
  <span 
    *ngIf="approved; else rejectedIcon"
    [nbTooltip]="active ? 'Active': 'Archived'"
    nbTooltipStatus="primary" 
    [ngClass]="{'resolve-status': true, 'approved': true, 'active': active }">
    <nb-icon icon="check-circle" pack="fa"></nb-icon>
  </span>
</ng-template>

<ng-template #rejectedIcon>
  <span 
    *ngIf="rejected"
    nbTooltip="Rejected"
    nbTooltipStatus="primary" 
    [ngClass]="{'resolve-status': true, 'rejected': true}">
    <nb-icon icon="times-circle" pack="fa"></nb-icon>
  </span>
</ng-template>