<div class="right-actions">
	<button 
		class="p-2"
		nbButton
		shape="round" 
		size="small" 
		(click)="handleExportBtnClick()" 
		[disabled]="exportBtnDisabled"
	><i class="fa fa-file-download fa-lg"></i>
	</button>

	<div class="btn-group" dropdown [insideClick]="true">
		<button class="filter-btn" nbButton dropdownToggle shape="round" size="small">
			<nb-icon class="fa-rotate-90" icon="bars" pack="fa" ></nb-icon>
			<nb-icon class="mt-1" icon="angle-down" pack="fa" ></nb-icon>
		</button> 
		<ul id="dropdown-cols" *dropdownMenu class="dropdown-menu dropdown-menu-right cols-dropdown">
			<li class="action-dropdown-item" *ngFor="let col of columns; let i = index">
				<nb-checkbox 
					[checked]="col.isSelected" 
					[disabled]="!col.isUnselectable" 
					class="col-filter-checkbox"
					(checkedChange)="onSelectionChange($event, i)"
				>{{col.title}}</nb-checkbox>
			</li>
		</ul>
	</div>

	<button 
		class="p-2"
		nbButton
		shape="round" 
		size="small" 
		(click)="onAdvanceFilterClicked()"
	><i class="fa fa-sliders-h"></i>
	</button>
</div>