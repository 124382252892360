import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { map, mergeMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { RecordStatusTypes } from '../typings';
import { HubsService } from './hubs.service';

@Injectable({
  providedIn: 'root'
})
export class ExperiencesService {

  private _collectionName: string = 'experiences';

  constructor(
    private ngFirestore: AngularFirestore,
    private fireRTDb: AngularFireDatabase,
    private hubService: HubsService,
  ) { }

  public fetchExperiencesList() {
    return this.ngFirestore.collection(this._collectionName).valueChanges({ idField: 'id' }).pipe(
      map(dbRecords => {
        return dbRecords.map((dataItem: any) => {
          // Include Associated Hub
          const hubObservable = this.hubService.fetchHubById(dataItem.hubId);
          const hostObservable = this.fireRTDb.object(`users/${dataItem.uid}`).valueChanges();

          const combinedData = combineLatest(
            hubObservable,
            hostObservable,
            (hubRecord, hostRecord) => {
              return { hubRecord, hostRecord };
            });

          return combinedData.pipe(
            map((nestedData: any) => {
              return {
                ...dataItem,
                createdAt: (dataItem.createdDate && dataItem.createdDate.toDate()) || dataItem.createdDate,
                hub: nestedData.hubRecord,
                host: nestedData.hostRecord,
                isFeatured: dataItem.isFeatured ? dataItem.isFeatured : false,
                priority: dataItem.priority ? dataItem.priority : 1000
              };
            })
          );
        });
      }),
      mergeMap(observables => combineLatest(observables))
    );
  }

  public fetchRecordById(recordId: string) {
    return this.ngFirestore.collection(this._collectionName).doc(recordId).get().pipe(
      map((docResult: DocumentSnapshot<any>) => {
        return docResult.data();
      })
    );
  }

  public update(id: string, data) {
    return this.ngFirestore.collection(this._collectionName).doc(id)
      .update(data);
  }

  public countActiveRecords() {
    return this.ngFirestore.collection(this._collectionName, ref => ref.where('status', '==', 'active')).get().pipe(
      map((docsResult: QuerySnapshot<any>) => {
        return docsResult.docs.length;
      })
    );
  }

  public updateRecordStatus(recordId: string, newStatus: RecordStatusTypes, oldStatus: RecordStatusTypes) {
    return this.ngFirestore.collection(this._collectionName).doc(recordId).update({
      status: newStatus,
      prevStatus: (newStatus === RecordStatusTypes.DELETED) ? oldStatus : null
    });
  }

  public removeRecordPermanently(recordId: string) {
    return this.ngFirestore.collection(this._collectionName).doc(recordId).delete();
  }

  public handleBulkUpdate(selectedRecord: string[], newStatus: string) {
    const batch = this.ngFirestore.firestore.batch();
    const collectionRef = this.ngFirestore.collection(this._collectionName).ref;

    for (const id of selectedRecord) {
      batch.update(collectionRef.doc(id), {
        status: newStatus,
        prevStatus: 'pending'
      });
    }

    return batch.commit();

  }

  public updateCountCategories(collectionName, catName) {
    return this.ngFirestore.collection(collectionName, ref => ref.where('name', '==', catName)).get().pipe(
      map(data => {
        return data.docs.map(docItem => {
          return {
            id: docItem.id,
            ...(docItem.data() as object)
          };
        });
      })
    );
  }
  public updateCount(collectionName: string, docId: string, count: any) {
    const collectionRef = this.ngFirestore.collection(collectionName).ref;
    return collectionRef.doc(docId).update({
      count: count
    });
  }
}
