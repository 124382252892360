<div class="sublist-pop p-3">
  <div class="closing-header">
      <button nbButton ghost size="tiny" (click)="closeDialog()">
          <nb-icon icon="times" pack="fa"></nb-icon> Close
      </button>
      <hr class="my-2" />
  </div>
  <div class="detail-main">
      <h3 class="title text-center">{{title | titlecase}} List</h3>
      <div class="detail-item">
          <nb-list>
              <nb-list-item *ngFor="let listItem of list">
                <ng-container *ngIf="displayKey; else keyLessValue">{{ listItem[displayKey] }}</ng-container>
                <ng-template #keyLessValue>{{ listItem }}</ng-template>
              </nb-list-item>
          </nb-list>
      </div>
  </div>
</div>