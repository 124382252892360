import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'admin-listitem-status',
  templateUrl: './listitem-status.component.html',
  styleUrls: ['./listitem-status.component.scss']
})
export class ListitemStatusComponent implements OnInit {

  @Input() deleted: boolean = false;
  @Input() pending: boolean = false;
  @Input() drafted: boolean = false;
  @Input() approved: boolean = false;
  @Input() rejected: boolean = false;
  @Input() active: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
