<div 
  *ngIf="(list && list.length)"
  class="sublist"
  nbTooltip="View All"
  nbTooltipStatus="primary"
  (click)="openSublistModal()"
>
  <div class="list">
      <span *ngFor="let listItem of list | slice:0:4; let itemIndex = index">
        <ng-container *ngIf="displayKey; else keyLessValue">{{ listItem[displayKey] }}</ng-container>
        <ng-template #keyLessValue>{{ listItem }}</ng-template>
          <small *ngIf="itemIndex !== (list.length - 1)">, </small>
      </span>
      <span *ngIf="list.length > 4">...</span>
  </div>
  <div class="ml-2 more-icon">
      <i class="fa fa-chevron-right"></i>
  </div>
</div>