import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { ListingColumn } from '../../../@core/typings';
import { ngxCsv } from 'ngx-csv';
import { TableService } from '../../../@core/utils';

@Component({
  selector: 'admin-listing',
  template: '',
})
export class ListingComponent implements OnDestroy {

  public destroy$: Subject<void> = new Subject<void>();
  public csvExportFileName: string;
  protected flagBasedStatus: boolean = true;
  public colsList: ListingColumn[] = [];
  
  // columns to display
  public displayedCols = [];

  // store the db result in this and replace 'transactions' everywhere
  protected dbRows = [];
  protected allRows = [];

  // rows to display in table
  public filteredRows = [];
  public selectedRows = [];

  public advancedFilters = {
    currentPage: 1,
    pageSize: 50,
    pageStart: 0,
    pageEnd: 0,
    filterStatus: [],
    creationDate: {
      start: null,
      end: null
    }
  }

  // filters
  public searchTerm = new FormControl('');
  public isExportBtnDisabled: boolean = false;
  public isDataLoading: boolean = true;

  constructor(
    protected tableService: TableService,
  ) {
  }
  
  public toggleColumnSelection({selection, index}: {selection: boolean, index: number}) {
    this.colsList[index].isSelected = selection;
    this.displayedCols = this.colsList.filter(col => col.isSelected);
  }

  public changeRowSelection(isChecked: boolean, rowID: any) {
    if (isChecked) {
      this.selectedRows.push(rowID);
    } else {
      this.selectedRows = this.selectedRows.filter(row => row !== rowID);
    }
  }

  public toggleAllSelection(isChecked: boolean) {
    if (isChecked) {
      this.selectedRows = this.filteredRows.map(row => row.id);
    } else {
      this.clearSelectedRows();
    }
  }

  protected filterOnSearch(search: string) {
    const lowcaseSearch = search ? search.toLowerCase() : search;
    this.filteredRows = this.allRows.filter(row => {
      if (!lowcaseSearch) {
        return true;
      }
      for (const col of this.displayedCols) {
        if (row[col.name] && (row[col.name]).toString().toLowerCase().includes(lowcaseSearch)) {
          return true;
        }
      }
      return false;
    });
    this.clearSelectedRows();
    this.handlePagination(1, this.advancedFilters.pageSize, this.filteredRows.length)

  }

  protected handleFilterModalClose(result: any) {
    if(!result) {
      return;
    }

    this.applyAdvancedFilters(result, this.dbRows);

    this.advancedFilters = {
      ...this.advancedFilters,
      ...result,
    }
  }

  protected clearSelectedRows(){
    this.selectedRows = [];
  }

  public handleSortEvent(sort: any) {
    // Reset Paging
    this.handlePagination(1, this.advancedFilters.pageSize, this.filteredRows.length);
  }

  public handlePageChange(pageChange: any) {
    this.advancedFilters.currentPage = (pageChange.offset + 1);
    this.handlePagination(pageChange.offset + 1, pageChange.pageSize, pageChange.count);
  }

  protected applyAdvancedFilters(filters: any, records: any[] ) {

    this.isDataLoading = true;
    this.filteredRows = [];
    this.selectedRows = [];

    const specificData = this.tableService.getFilteredData(filters, records, this.flagBasedStatus);

    this.allRows = specificData;
    this.filteredRows = specificData;

    this.handlePagination(1, filters.pageSize, specificData.length);
    this.isDataLoading = false;
  }

  protected handlePagination(offset: number, size: number, count: number) {

    this.advancedFilters.currentPage = offset;
    this.advancedFilters.pageSize = size;

    const { start, end } = this.tableService.getRecordsDisplayRange(offset, size, count);

    this.advancedFilters.pageStart = start;
    this.advancedFilters.pageEnd = end;
  }

  public exportDataToCSV() {
    this.isExportBtnDisabled = true;
    const displayedCols = this.displayedCols.map(col => col.name);
    const dataToExport = this.filteredRows.map(row => {
      const newRow = {};

      for (const key in row) {
        if (displayedCols.includes(key)) {
          newRow[key] = row[key];
        }
      }

      return newRow;
    });

    new ngxCsv(dataToExport, this.csvExportFileName);

    this.isExportBtnDisabled = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
