import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListingColumn } from '../../../@core/typings';

type SelectionEvent = {
  selection: boolean,
  index: number
}

@Component({
  selector: 'admin-listings-filter-actions',
  templateUrl: './listings-filter-actions.component.html',
  styleUrls: ['./listings-filter-actions.component.scss']
})
export class ListingsFilterActionsComponent implements OnInit {

  @Input() exportBtnDisabled: boolean;
  @Input() columns: ListingColumn[] = [];
  @Output() exportBtnClick: EventEmitter<void> = new EventEmitter();
  @Output() selectionChange: EventEmitter<SelectionEvent> = new EventEmitter();
  @Output() advanceFilterClick: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public handleExportBtnClick() {
    this.exportBtnClick.emit();
  }

  public onSelectionChange(selection: boolean, index: number) {
    this.selectionChange.emit({
      index: index,
      selection: selection
    });
  }

  public onAdvanceFilterClicked() {
    this.advanceFilterClick.emit();
  }
}
