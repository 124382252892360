import { Component, OnInit, Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { SublistModalComponent } from '../sublist-modal/sublist-modal.component';

@Component({
  selector: 'shared-sublist-listing-cell',
  templateUrl: './sublist-listing-cell.component.html',
  styleUrls: ['./sublist-listing-cell.component.scss']
})
export class SublistListingCellComponent implements OnInit {

  @Input() list: any[] | null;
  @Input() displayKey: string;
  @Input() dialogTitle: string;

  constructor(
    private dialogService: NbDialogService,
  ) { }

  ngOnInit(): void {
  }

  public openSublistModal() {
    this.dialogService.open(SublistModalComponent, {
      dialogClass: 'central-dialog',
      context: {
        title: this.dialogTitle,
        list: this.list,
        displayKey: this.displayKey,
      },
    });
  }
}
