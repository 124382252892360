import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { UsersService } from './users.service';
import { map, mergeMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HubsService {

  private _collectionName: string = 'agencies';

  constructor(
    private fireRTDb: AngularFireDatabase,
    private usersService: UsersService,
  ) { }

  public fetchHubsList() {
    return this.fireRTDb.list(this._collectionName).valueChanges(null, { idField: 'id' }).pipe(
      map(records => {
        return records.map((recordItem: any) => {
          let user$ = this.usersService.fetchUserById(recordItem.expertUid);
          const combinedData = combineLatest(user$, (creator) => {
            return creator;
          });
          return combinedData.pipe(
            map((nestedData: any) => {
              return {
                ...recordItem,
                experts_count: (recordItem.experts ? recordItem.experts.length : 0),
                hub_plan: recordItem.hub_plan || 'hub',
                created_by: (nestedData ? nestedData.fullName : ''),
                creator: nestedData,
                priority: recordItem.priority ? recordItem.priority : 1000
              };
            })
          );
        });
      }),
      mergeMap(observables => combineLatest(observables))
    );
  }

  public fetchHubById(id: string) {
    // let result: Promise<any>;
    if (id) {
      return this.fireRTDb.object(`${this._collectionName}/${id}`).valueChanges();
    } else {
      return Promise.resolve(null);
    }
    // return from(result);
  }

  public toggleApproval(docId: string, isApproved: boolean) {
    const collectionRef = this.fireRTDb.object(`${this._collectionName}/${docId}`);
    return collectionRef.update({
      isApproved: isApproved,
      isRejected: !isApproved,
      status: isApproved ? 'active' : 'rejected'
    });
  }

  public toggleArchive(docId: string, isActive: boolean) {
    const collectionRef = this.fireRTDb.object(`${this._collectionName}/${docId}`);
    return collectionRef.update({
      isActive: isActive,
      status: isActive ? 'active' : 'archived'
    });
  }

  public updateHub(hubId: string, data: any) {
    const collectionRef = this.fireRTDb.object(`${this._collectionName}/${hubId}`);
    return collectionRef.update(data);
  }
  public toggleFeatureship(docId: string, isFeatured: boolean) {
    const collectionRef = this.fireRTDb.object(`${this._collectionName}/${docId}`);
    return collectionRef.update({
      isFeatured: isFeatured,
      status: isFeatured ? 'featured' : 'active'
    });
  }
  public toggleDeletion(docId: string, isDeleted: boolean) {
    const collectionRef = this.fireRTDb.object(`${this._collectionName}/${docId}`);
    return collectionRef.update({
      isDeleted: isDeleted,
      status: isDeleted ? 'deleted' : 'active'
    });
  }
  public update(docId: string, data: any) {
    const collectionRef = this.fireRTDb.object(`${this._collectionName}/${docId}`);
    return collectionRef.update(data);
  }

  public handleBulkActive(selectedRecords: string[], isActive: boolean) {
    const collectionRef = this.fireRTDb.database.ref().child(this._collectionName);

    const promises: Promise<any>[] = selectedRecords.map(id => (
      collectionRef.child(id).update({
        isActive: isActive,
        status: isActive ? 'active' : 'archived'
      })
    ));
    return Promise.all(promises);
  }

  public handleBulkApproval(selectedRecords: string[], isApproved: boolean) {
    const collectionRef = this.fireRTDb.database.ref().child(this._collectionName);

    const promises: Promise<any>[] = selectedRecords.map(id => (
      collectionRef.child(id).update({
        isApproved: isApproved,
        isRejected: !isApproved,
        status: isApproved ? 'active' : 'rejected'
      })
    ));
    return Promise.all(promises);
  }

  public handleBulkFeature(selectedRecords: string[], isFeatured: boolean) {
    const collectionRef = this.fireRTDb.database.ref().child(this._collectionName);

    const promises: Promise<any>[] = selectedRecords.map(id => (
      collectionRef.child(id).update({
        isFeatured: isFeatured,
        status: isFeatured ? 'featured' : 'active'
      })
    ));
    return Promise.all(promises);
  }

  public handleBulkDeletion(selectedRecords: string[], isDeleted: boolean) {
    const collectionRef = this.fireRTDb.database.ref().child(this._collectionName);

    const promises: Promise<any>[] = selectedRecords.map(id => (
      collectionRef.child(id).update({
        isDeleted: isDeleted,
        status: isDeleted ? 'deleted' : 'active'
      })
    ));
    return Promise.all(promises);
  }

  public deleteRecordPermanently(docId: string) {
    return this.fireRTDb.database.ref().child(this._collectionName).child(docId).remove();
  }
}
