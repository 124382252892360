import { Injectable } from '@angular/core';
import { RecordStatusTypes } from '../typings';
import * as dayjs from 'dayjs';
import * as isBetween from 'dayjs/plugin/isBetween';

interface StatusFlags {
  isDeleted: boolean,
  isActive: boolean,
  isPublished: boolean,
  isRejected: boolean,
  isApproved: boolean,
  isFeatured: boolean,
  isCompleted: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor() {
    dayjs.extend(isBetween);
  }

  /**
   * @param page number
   * @param pageSize number
   * @param length number
   * 
   * @returns  
   * {{start: number, end: number}} range of the displayed rows
   */
  public getRecordsDisplayRange(page: number, pageSize: number, length: number) {
    
    const recordRange = {
      start: 0,
      end: 0
    };

    if (length == 0 || pageSize == 0) {
      return recordRange;
    }

    length = Math.max(length, 0);

    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex < length ? (Math.min(startIndex + pageSize, length)) :(startIndex + pageSize);

    recordRange.start = (startIndex + 1);
    recordRange.end = endIndex;

    return recordRange;

  }

  /**
   * parseStatusFlags
   * @description converts boolean falgs string statuses - which are applied on certain flags
   */
  public parseStatusFlags(flags: Partial<StatusFlags>): string[] {

    // deleted, active, archived, published, pending, rejected, approved, featured

    if (flags.isDeleted) {
      return [RecordStatusTypes.DELETED];
    }

    if (flags.isRejected) {
      return [RecordStatusTypes.REJECTED];
    }

    if (!flags.isPublished && !flags.isApproved) {
      return [RecordStatusTypes.PENDING];
    }

    if (!flags.isCompleted && !flags.isPublished && !flags.isApproved) {
      return [RecordStatusTypes.DRAFTED];
    }

    if ((flags.isPublished || flags.isApproved) && flags.isActive && flags.isFeatured) {
      return [RecordStatusTypes.FEATURED, RecordStatusTypes.APPROVED, RecordStatusTypes.ACTIVE];
    }

    if ((flags.isPublished || flags.isApproved) && flags.isActive) {
      return [RecordStatusTypes.ACTIVE, RecordStatusTypes.APPROVED];
    }

    return [RecordStatusTypes.ARCHIVED, RecordStatusTypes.APPROVED];
  }

    /**
   * parseStatusString
   * @description converts string statuses - which are applied on certain flags
   */
  public parseStatusString(status: string): string[] {

    // deleted, active, archived, published, pending, rejected, approved, featured

    if (status === RecordStatusTypes.DELETED) {
      return [RecordStatusTypes.DELETED];
    }

    if (status === RecordStatusTypes.REJECTED) {
      return [RecordStatusTypes.REJECTED];
    }
    
    if (status === RecordStatusTypes.DRAFTED || status === RecordStatusTypes.PENDING) {
      return [RecordStatusTypes.DRAFTED, RecordStatusTypes.PENDING];
    }

    if (status === RecordStatusTypes.FEATURED) {
      return [RecordStatusTypes.FEATURED, RecordStatusTypes.APPROVED, RecordStatusTypes.ACTIVE];
    }

    if (status === RecordStatusTypes.ACTIVE) {
      return [RecordStatusTypes.ACTIVE, RecordStatusTypes.APPROVED];
    }

    return [RecordStatusTypes.ARCHIVED, RecordStatusTypes.APPROVED];
  }

  public getFilteredData(filters: any, records: any[], flagsBasedStatus: boolean, dateField: string = 'createdAt'): any[] {

    // If no status filter provided, then show
    // 'pending', 'active', 'rejected'
    const appliedStatus: string[] = (filters.filterStatus.length > 0) ? filters.filterStatus : [RecordStatusTypes.ACTIVE, RecordStatusTypes.PENDING, RecordStatusTypes.REJECTED];
    
    // filter out records based on filters
    const specificData: any[] = records.filter((record:any) => {
      // check date-range-filter
      if(record[dateField] && filters.creationDate.start && filters.creationDate.end) {
        const startRange = dayjs(filters.creationDate.start).startOf('date');
        const endRange = dayjs(filters.creationDate.end).endOf('date');
        const currentDate = dayjs(record[dateField]);
        
        if (!currentDate.isBetween(startRange, endRange)) {
          return false;
        }
      }

      // check status filters
      const recordStatus: string[] = flagsBasedStatus ? this.parseStatusFlags({
        isActive: record.isActive,
        isDeleted: record.isDeleted,
        isApproved: record.isApproved,
        isRejected: record.isRejected,
        isFeatured: record.isFeatured,
        isPublished: record.isPublished,
        isCompleted: record.isCompleted,
      }): this.parseStatusString(record.status);

      return appliedStatus.some(appliedStatus => recordStatus.includes(appliedStatus));
    });

    return specificData;

  }
}
