import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { AdminRoles } from '../typings';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  
  private collectionRef: CollectionReference

  constructor(
    private ngFirestore: AngularFirestore,
  ) {
    this.collectionRef = ngFirestore.collection('admins').ref;
  }

  public createAdmin(id: string, name: string, email: string, role: AdminRoles) {

    return this.collectionRef.doc(id).set({
      displayName: name,
      email: email,
      isActive: true,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      role: role
    })
  }

  public getAdminById(id: string): Promise<any> {
    return this.collectionRef.doc(id).get().then(result => result.data());
  }
}
