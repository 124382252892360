import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SpaceCategory } from '../typings';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  private collections = {
    main: 'utils',
    hubs_categories: {
      collectionName: 'hubs_categories'
    },
    expertise_main: {
      collectionName: 'expertise_categories'
    },
    expertise_subcat: {
      collectionName: 'expertise_subcategories'
    },
    expertise_skill: {
      collectionName: 'expertise_skills'
    },
    experiences_main: {
      collectionName: 'experiences_types'
    },
    experiences_subcat: {
      collectionName: 'experiences_themes'
    },
    experiences_topic: {
      collectionName: 'experiences_topics'
    },
    spaces_category: {
      collectionName: 'spaces_type'
    },
    spaces_subcat: {
      collectionName: 'space_categories'
    },
    spacesCategories: 'spacesCategories',
  }

  constructor(
    private ngFireStore: AngularFirestore,
  ) { }

  public fetchList(collectionType: string) {
    return this.ngFireStore.collection(this.collections[collectionType].collectionName).valueChanges({idField: 'id'});
  }

  public getSpacesCategories(): Observable<SpaceCategory[]> {
    // console.log('this.collections.spacesCategories--', this.collections.spacesCategories);
    return this.ngFireStore.collection(this.collections.main).doc(this.collections.spacesCategories)
      .valueChanges().pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  public updateSpacesCategories(updatedCats: SpaceCategory[]) {
    return this.ngFireStore.collection(this.collections.main).doc(this.collections.spacesCategories).update({
      data: updatedCats
    });
  }

  public embedExpertiesSubCategories(result: Observable<any[]>) {
    return result.pipe(
      map(data => {
        return data.map(dataItem => {
          let subCatObservable = this.fetchExpertiesSubCategoryByParent(dataItem.id);

          const combinedData = combineLatest(subCatObservable, (data1) => {
            return  data1;
          });

          return combinedData.pipe(
            map(nestedData => {
              return {
                ...dataItem,
                subcategories: nestedData
              }
            })
          )
        });
      }),
      mergeMap(observables => combineLatest(observables))
    )
  }

  public embedExpertiesMainCategories(result: Observable<any[]>) {
    return result.pipe(
      map(data => {
        return data.map(dataItem => {
          let subCatObservable = this.fetchExpertiesMainCatById(dataItem.parentCategory);

          const combinedData = combineLatest(subCatObservable, (data1) => {
            return  data1;
          });

          return combinedData.pipe(
            map(nestedData => {
              return {
                ...dataItem,
                parent: nestedData
              }
            })
          )
        });
      }),
      mergeMap(observables => combineLatest(observables))
    )
  }

  public embedExpertiesList(result: Observable<any[]>) {
    return result.pipe(
      map(data => {
        return data.map(dataItem => {
          let expertiseObservables = this.fetchExpertiesSubCategoryByIds(dataItem.expertise);

          const combinedData = combineLatest(expertiseObservables, (data1) => {
            return  data1;
          });

          return combinedData.pipe(
            map(nestedData => {
              return {
                ...dataItem,
                expertise: nestedData
              }
            })
          )
        });
      }),
      mergeMap(observables => combineLatest(observables))
    )
  }

  public embedParentTheme(result: Observable<any[]>) {
    return result.pipe(
      map(data => {
        return data.map(dataItem => {
          let subCatObservable = this.fetchThemeById(dataItem.parentCategory);

          const combinedData = combineLatest(subCatObservable, (data1) => {
            return  data1;
          });

          return combinedData.pipe(
            map(nestedData => {
              return {
                ...dataItem,
                theme: nestedData
              }
            })
          )
        });
      }),
      mergeMap(observables => combineLatest(observables))
    )
  }

  public embedTopicsList(result: Observable<any[]>) {
    return result.pipe(
      map(data => {
        return data.map(dataItem => {
          let expertiseObservables = this.fetchTopicsByParent(dataItem.id);

          const combinedData = combineLatest(expertiseObservables, (data1) => {
            return  data1;
          });

          return combinedData.pipe(
            map(nestedData => {
              return {
                ...dataItem,
                topics: nestedData
              }
            })
          )
        });
      }),
      mergeMap(observables => combineLatest(observables))
    )
  }

  private fetchExpertiesMainCatById(id: string) {
    return this.ngFireStore.collection(
      this.collections['expertise_main'].collectionName).doc(id).get().pipe(
        map(data => {
          return {
            id: data.id,
            ...(data.data() as object)
          }
        })
      );
  }

  private fetchThemeById(id: string) {
    return this.ngFireStore.collection(
      this.collections['experiences_subcat'].collectionName).doc(id).get().pipe(
        map(data => {
          return {
            id: data.id,
            ...(data.data() as object)
          }
        })
      );
  }

  private fetchExpertiesSubCategoryByParent(parentId: string) {
    return this.ngFireStore.collection(
      this.collections['expertise_subcat'].collectionName,
      ref => ref.where('parentCategory', '==', parentId)).get().pipe(
        map(data => {
          return data.docs.map(docItem => {
            return {
              id: docItem.id,
              ...(docItem.data() as object)
            }
          });
        })
      );
  }

  private fetchTopicsByParent(parentId: string) {
    return this.ngFireStore.collection(
      this.collections['experiences_topic'].collectionName,
      ref => ref.where('parentCategory', '==', parentId)).get().pipe(
        map(data => {
          return data.docs.map(docItem => {
            return {
              id: docItem.id,
              ...(docItem.data() as object)
            }
          });
        })
      );
  }

  private fetchExpertiesSubCategoryByIds(idsList: string[]) {
    return this.ngFireStore.collection(
      this.collections['expertise_subcat'].collectionName,
      ref => ref.where('__name__', 'in', idsList)).get().pipe(
        map(data => {
          return data.docs.map(docItem => {
            return {
              id: docItem.id,
              ...(docItem.data() as object)
            }
          });
        })
      );
  }

  public publishRecord(collectionType: string, docId: string) {
    const collectionRef = this.ngFireStore.collection(this.collections[collectionType].collectionName).ref;
    return collectionRef.doc(docId).update({
      isPublished: true,
      isActive: true,
    });
  }

  public toggleArchive(collectionType: string, docId: string, isActive: boolean) {
    const collectionRef = this.ngFireStore.collection(this.collections[collectionType].collectionName).ref;
    return collectionRef.doc(docId).update({
      isActive: isActive,
    });
  }

  public toggleDeletion(collectionType: string, docId: string, isDeleted: boolean) {
    const collectionRef = this.ngFireStore.collection(this.collections[collectionType].collectionName).ref;
    return collectionRef.doc(docId).update({
      isDeleted: isDeleted,
    });
  }

  public handleBulkActive(collectionType: string, selectedRecords: string[], isActive: boolean) {
    const collectionRef = this.ngFireStore.collection(this.collections[collectionType].collectionName).ref;
    const batchRef = this.ngFireStore.firestore.batch();

    for (const id of selectedRecords) {
      batchRef.update(collectionRef.doc(id), {
        isActive: isActive
      });
    }

    return batchRef.commit();
  }

  public handleBulkDeletion(collectionType: string, selectedRecords: string[]) {
    const collectionRef = this.ngFireStore.collection(this.collections[collectionType].collectionName).ref;
    const batchRef = this.ngFireStore.firestore.batch();

    for (const id of selectedRecords) {
      batchRef.update(collectionRef.doc(id), {
        isDeleted: true
      });
    }

    return batchRef.commit();
  }

  public deleteRecordPermanently(collectionType: string, docId: string) {
    const collectionRef = this.ngFireStore.collection(this.collections[collectionType].collectionName).ref;
    return collectionRef.doc(docId).delete();
  }

  public fetchExperiencesByCat() {
    return this.ngFireStore.collection('experiences',
      ref => ref.where('status', '==', 'active')).get().pipe(
      map(data => {
        return data.docs.map(docItem => {
          return {
            id: docItem.id,
            ...(docItem.data() as object)
          }
        });
      })
    );
  }

  public updateCount(collectionName: string, docId: string, count: any) {
    console.log(collectionName, docId, count)
    const collectionRef = this.ngFireStore.collection(collectionName).ref;
    return collectionRef.doc(docId).update({
      count: count
    });
  }
}
