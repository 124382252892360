import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubTab } from '../../../@core/typings';

@Component({
  selector: 'admin-sub-tabs',
  templateUrl: './sub-tabs.component.html',
  styleUrls: ['./sub-tabs.component.scss']
})
export class SubTabsComponent implements OnInit {

  @Input() subTabs: SubTab[] = [];
  @Input() activeTab: string;

  @Output() tabChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  public handleClick(newTab: string) {
    if (newTab !== this.activeTab) {
      this.tabChange.emit(newTab);
    }
    return;
  }

}
