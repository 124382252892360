import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private ngFirestore: AngularFirestore
  ) { }

  public generateId(): string {
    return this.ngFirestore.createId();
  }
}
