// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyCf_h31R2MVvIkl9B6a09g-Ph18vasHf0U",
//     authDomain: "mereka-dev.firebaseapp.com",
//     databaseURL:
//       "https://mereka-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "mereka-dev",
//     storageBucket: "mereka-dev.appspot.com",
//     messagingSenderId: "743941526599",
//     appId: "1:743941526599:web:7d774cb554167b6ca20d62",
//     measurementId: "G-3KEHNXC6XG",
//   },
// };
// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyCf_h31R2MVvIkl9B6a09g-Ph18vasHf0U",
//     authDomain: "mereka-dev.firebaseapp.com",
//     databaseURL:
//       "https://mereka-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
//     projectId: "mereka-dev",
//     storageBucket: "mereka-dev.appspot.com",
//     messagingSenderId: "743941526599",
//     appId: "1:743941526599:web:7d774cb554167b6ca20d62",
//     measurementId: "G-3KEHNXC6XG",
//   },
// };
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDJ1hNgS9agYatYUWDssYT9wT0szaMeEPk",
    authDomain: "mereka-io-staging.firebaseapp.com",
    databaseURL: "https://mereka-io-staging-default-rtdb.firebaseio.com",
    projectId: "mereka-io-staging",
    storageBucket: "mereka-io-staging.appspot.com",
    messagingSenderId: "778972893213",
    appId: "1:778972893213:web:de4847e8f83cf58d70f6f4",
    measurementId: "G-P6JKYRYRRN",
  },
};

// export const environment = {
//   production: false,
//   firebaseConfig: {
//     apiKey: "AIzaSyBdCmrgsGG-pFNeoDnkVbhQplV1kXH0AqE",
//     authDomain: "mereka-a4ae2.firebaseapp.com",
//     databaseURL: "https://mereka-a4ae2.firebaseio.com",
//     projectId: "mereka-a4ae2",
//     storageBucket: "mereka-a4ae2.appspot.com",
//     messagingSenderId: "494732246522",
//     appId: "1:494732246522:web:20f33c6ca425096e563843",
//     measurementId: "G-NVDS8R10RR",
//   },
// };
