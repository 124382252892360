<div *ngIf="subTabs.length > 0" class="d-flex align-items-center">
  <nb-button-group ghost class="buttons-group">
    <button 
        [ngClass]="{'group-btn': true, 'active': btn.value === activeTab, 'inactive': btn.value !== activeTab}"
        nbButtonToggle
        shape="round" 
        size="small"
        *ngFor="let btn of subTabs" 
        (click)="handleClick(btn.value)"
    >{{btn.display}}</button>
  </nb-button-group>
</div>