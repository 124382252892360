import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'admin-clickable-title',
  templateUrl: './clickable-title.component.html',
  styleUrls: ['./clickable-title.component.scss']
})
export class ClickableTitleComponent implements OnInit {

  @Input() name: string;
  @Input() picture: string;
  @Input() navigationPath: string[] = [];
  @Input() queryParams: object = {};
  @Input() onlyName: boolean = false;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  public navigateToDetailPage() {
    this.router.navigate(this.navigationPath, {relativeTo: this.currentRoute, queryParams: this.queryParams});
  }

}
