import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from "firebase/app";
import { LoginCredentials } from "../typings";
import { AdminService } from "../utils/admins.service";
import { Observable, from } from "rxjs";
import { mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _loggedInUser: firebase.User;

  constructor(
    private ngFireAuth: AngularFireAuth,
    private adminService: AdminService
  ) {
    ngFireAuth.authState.subscribe((state) => {
      if (state) {
        this._loggedInUser = state;
      } else {
        this._loggedInUser = null;
      }
    });
  }

  private emailLogin(email: string, password: string) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }

  public isAuthorized(): Observable<boolean> {
    const result = this.ngFireAuth.authState.pipe(
      mergeMap((result) => {
        if (!result) {
          return from(Promise.resolve(false));
        }
        return from(this.isAdmin(result.uid));
      })
    );

    return result;
  }

  private async isAdmin(id: string): Promise<boolean> {
    const userData: any = await this.adminService.getAdminById(id);
    return !!(userData && userData.isActive);
  }

  public async handleLogin(data: LoginCredentials) {
    try {
      await this.ngFireAuth.setPersistence("local");
      if (data.authType === "email") {
        const { user } = await this.emailLogin(
          data.credentials.email,
          data.credentials.password
        );

        // throw error if email isn't verified
        // if (!user.emailVerified) {
        //   throw ({message: 'Sorry, you must verify your email before login.'})
        // }

        // throw error if user ins't admin
        const isActiveAdmin = await this.isAdmin(user.uid);

        if (!isActiveAdmin) {
          throw {
            message:
              "Sorry, you are not allowed to access these content. If you have existing account, please ask admin support to activate it.",
          };
        }

        // else return successfull
        return user;
      } else {
        throw {
          message:
            "Sorry, provided method of authentication is not available yet.",
        };
      }
    } catch (error) {
      // destory session
      // return error message

      this.handleLogout();
      throw error;
    }
  }

  public handleLogout() {
    return this.ngFireAuth.signOut();
  }

  public handlePasswordRequest(email: string) {
    return this.ngFireAuth.sendPasswordResetEmail(email, {
      url: `${location.origin}/auth/login`,
    });
  }

  public handleNewPassword(code: string, password: string) {
    return this.ngFireAuth.confirmPasswordReset(code, password);
  }

  public get currentUser() {
    return this._loggedInUser;
  }
}
