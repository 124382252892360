import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'shared-sublist-modal',
  templateUrl: './sublist-modal.component.html',
  styleUrls: ['./sublist-modal.component.scss']
})
export class SublistModalComponent implements OnInit {

  @Input() title: string;
  @Input() list: any[] | null;
  @Input() displayKey: string;

  constructor(private dialogRef: NbDialogRef<SublistModalComponent>) { }

  ngOnInit(): void {
  }

  public closeDialog() {
    this.dialogRef.close();
  }

}
