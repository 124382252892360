import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertToastrService {

  constructor(
    private toastr: ToastrService
  ) { }

  public showSuccess(title: string, message: string) {
    this.toastr.success(message, title);
  }

  public showError(title: string, message: string) {
    this.toastr.error(message, title);
  }
}
